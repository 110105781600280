import React from "react";
import WRPSectionWizard from "../WRPSectionWizard";
import PropTypes from "prop-types";

export default function EPCSectionWizard(props) {
  function buildSectionStructure(
    buildDefaultSection,
    buildSelectCategoriesSection,
    buildExtraStepForContext
  ) {
    // The EPCSectionWizard is a bit different than the other section wizards
    // as there are two seperate request forms, and there are no categories
    // We also want to give the requestor the "feel" as if this is one request
    if (props.component.inputOptions.name === "aanvraag_taak_1_epc") {
      return [
        buildDefaultSection(0),
        buildExtraStepForContext(2, "gegevensEpc", "Gegevens EPC", " ", false),
        buildExtraStepForContext(
          3,
          "aanvragerGegevens",
          "Gegevens aanvrager",
          "Vul uw gegevens (of die van de aanvrager) in.",
          false
        ),
        buildExtraStepForContext(
          4,
          "communicatieEnBetaling",
          "Communicatie en betaling",
          " ",
          false
        ),
        buildExtraStepForContext(5, "overzicht", "Overzicht", "", false),
      ];
    }

    if (props.component.inputOptions.name === "aanvraag_taak_2_epc") {
      return [
        buildExtraStepForContext(
          1,
          "gegevensEigendom",
          "Gegevens eigendom",
          "Deze gegevens kunnen niet meer gewijzigd worden. <br>Start een nieuwe aanvraag indien u dit toch wenst.",
          true
        ),
        buildDefaultSection(1, 2),
        buildDefaultSection(2, 3),
        buildDefaultSection(3, 4),
        buildDefaultSection(4, 5),
      ];
    }
  }

  const title = "Aanvraag Mijn EPC-Labelpremie";

  const amountOfComponents = 4;

  // We need to specify this because we want to skip the first component and we need the button to submit be available on the last.
  let sectionsLength = null;
  if (props.component.inputOptions.name === "aanvraag_taak_2_epc") {
    sectionsLength = 4;
  }

  const welcomeText = (
    <>
      <p>
        Via onderstaande stappen kunt u Mijn EPC-Labelpremie aanvragen.
        Controleer op voorhand of u voldoet aan alle voorwaarden om in
        aanmerking te komen. U kunt de premie pas aanvragen nadat u een geldig
        EPC-label na de renovatiewerken hebt laten opstellen. De EPC's worden
        automatisch uit de databank opgehaald. Indien relevant, voeg het
        bewijsstuk voor verhuur aan een woonmaatschappij toe. Zorg dat u deze
        ter beschikking hebt alvorens u start met een aanvraag. Lees meer over{" "}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="vl-link"
          href="www.vlaanderen.be/epclabelpremie"
          rel="noreferrer"
          target="_blank"
        >
          MijnEPCLabelpremie
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            aria-hidden="true"
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
          />
        </a>
        en ons {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="vl-link"
          href="https://www.vlaanderen.be/bouwen-wonen-en-energie/bouwen-en-verbouwen/premies-en-belastingvoordelen/mijn-verbouwpremie/privacyverklaring-mijn-verbouwpremie"
          rel="noreferrer"
          target="_blank"
        >
          Privacybeleid
          <span className="vl-u-visually-hidden">(opent in nieuw venster)</span>
          <span
            aria-hidden="true"
            className="vl-link__icon vl-link__icon--after vl-link__icon--light vl-vi vl-vi-external"
          />
        </a>
      </p>
    </>
  );

  return (
    <WRPSectionWizard
      buildSectionStructure={buildSectionStructure}
      title={title}
      welcomeText={welcomeText}
      amountOfComponents={amountOfComponents}
      sectionsLength={sectionsLength}
      {...props}
    />
  );
}

EPCSectionWizard.propTypes = {
  component: PropTypes.shape({
    label: PropTypes.string,
    components: PropTypes.array,
    sections: PropTypes.array,
    inputOptions: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};
