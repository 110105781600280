export default {
  mijn_verbouwpremie: {
    title: "Mijn VerbouwPremie",
    phone: "1700",
    websiteUrl: "https://www.mijnverbouwpremie.be",
    extraInformation:
      "U kan contact met ons opnemen door hierboven 'stel een vraag' uit te voeren. We behandelen uw vraag zo spoedig mogelijk.",
  },
  mijn_verbouwlening: {
    title: "Mijn VerbouwLening",
    phone: "1700",
    websiteUrl: "http://www.mijnverbouwlening.be",
    extraInformation:
      "Neem contact op met het Energiehuis in uw buurt: https://www.mijnenergiehuis.be. Zij beantwoorden graag al uw vragen over Mijn VerbouwLening.",
  },
  default: {
    title: "Mijn VerbouwLoket",
    phone: "1700",
    websiteUrl: "www.vlaanderen.be/epclabelpremie",
    extraInformation:
      "U kunt contact met ons opnemen door hierboven 'stel een vraag' uit te voeren. We behandelen uw vraag zo spoedig mogelijk.",
  },
};
