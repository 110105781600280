export default {
  AUTHENTIC_DATA_STARTED: "authentiekeDataOpgestart",
  REQUEST_SUBMITTED: "aanvraagIngediend",
  DOSSIER_DEF_REJECTED_MVL: "dossierDefinitiefGeweigerdMvl",
  REQUEST_INITIATED: "aanvraagOpgestart",
  FETCH_DATA_CITIZEN: "dataOphalenParticulieren",
  FILLING_IN_REQUEST: "aanvraagInvullen",
  REQUEST_INITIATED_IN_BO: "aanvraagGestartInBO",
  IN_BEHANDELING_MVL: "inBehandelingMVL",
  GEWEIGERD_MVL: "geweigerdMVL",
  GEANNULEERD_MVL: "geannuleerdMVL",
  GOEDGEKEURD_MVL: "goedgekeurdMVL",
  ACHTERSTAL_MVL: "achterstalMVL",
  OPGEZEGD_MVL: "opgezegdMVL",
  TERUGBETAALD_MVL: "terugbetaaldMVL",
};
